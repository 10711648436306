














































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

@Component({
  components: {
    
  }
})
export default class TagChip extends Vue
{
    @Prop() attrs!: any;
    @Prop() item!: string;
    @Prop() select!: any;
    @Prop() selected!: any;
    @Prop() closeable!: boolean;
    @Prop({default: false}) small!: boolean;
    @Prop({default: false}) small_icon!: boolean;
    @Prop({default: "#121124"}) color!: string;
    @Prop({default: ""}) left_icon!: string;
    @Prop({default: false}) editable!: boolean;

    @Emit()
    remove(tag: string): string {
        return tag;
    }
}
